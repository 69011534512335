@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@400;600;700;800;900&display=swap');

@font-face {
	font-family: 'SuperCorn';
	src:
			url('./asset/font/SuperCorn/SuperCorn.woff') format('woff'),
			url('./asset/font/SuperCorn/SuperCorn.woff2') format('woff2'),
			url('./asset/font/SuperCorn/SuperCorn.ttf') format('true-type');
	font-style: normal;
	font-weight: 400;
}