.gameCard {
	//width: 50%;
	//width: 100%;

	flex: 0 50%;
	margin-bottom: 40px;
	&:nth-child(odd){
		padding-right: 20px;
		
	}
	&:nth-child(even){
		padding-left: 20px;
		
	}
	&-wrapper{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		background: $white;
		border-radius: 40px;
		padding: 56px 40px;
	}
	&-image {
		max-width: 100%;
		margin-bottom: 24px;
	}

	&-text {
		margin-bottom: 12px;
	}

	&-link {
		position: relative;
		margin-bottom: 32px;
		display: flex;
		align-items: center;
		padding-bottom: 16px;

		p {
			margin: 0;
			font-weight: 800;
			text-transform: capitalize;
			color: #7429ff;
			font-size: 24px;
			line-height: 100%;
			margin-right: 20px;
		}

		&-arrow {
			padding-right: 12px;
		}

		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
			height: 2px;
			opacity: 0;
			background: #7429ff;
		}

		&:hover {
			&::before {
				opacity: 1;
				background: #7429ff;
			}
		}

		&:active {
			p {
				color: #4300bf;
			}

			&::before {
				background: #4300bf;
				opacity: 1;
			}
			svg{
				path{
					fill: #4300bf;
				}
			}
		}
	}

	&-stores {
		display: flex;
		justify-content: center;
		margin: 0 auto;
		gap: 32px;
	}
	
	@media (max-width: $tablet) {

		.gameCard-wrapper{
			padding: 40px 32px;

		}
		.gameCard-stores{
			justify-content: space-between;
		}
	}
	@media (max-width: $mobile-l) {
		margin-bottom: 32px;

		&:nth-child(odd){
			padding-right: 16px;
		}
		&:nth-child(even){
			padding-left: 16px;
		}
		.gameCard-wrapper{
			padding: 32px 24px;
		}
		.gameCard-text{
			p{
				font-size: 16px;
			}
		}

		.gameCard-link{
			margin-bottom: 24px;
			p{
				font-size: 20px;
			}
		}
		
	}
	@media (max-width: $mobile) {
		flex: 0 100%;
		margin-bottom: 24px;

		&:nth-child(odd){
			padding-right: unset;
		}
		&:nth-child(even){
			padding-left: unset;
		}
		.gameCard-link{
			p{
				font-size: 16px;
			}
		}
		
	}
	
}