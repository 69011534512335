.apps {
	position: relative;
	z-index: 2;
	background: url("./asset/image/apps-bg.png"), $secondary-gradient;
	//background: $primary-gradient;
	background-repeat: no-repeat;
	background-position: top right;
	background-size: cover;
	@media (max-width: $tablet) {
		background: url("./asset/image/apps-bg-tablet.png"), $secondary-gradient;
		//background: $primary-gradient;
		background-repeat: no-repeat;
		background-position: top right;
		background-size: cover;
	}
	@media (max-width: $mobile) {
		background: url("./asset/image/apps-bg-mobile.png"), $secondary-gradient;
		//background: $primary-gradient;
		background-repeat: no-repeat;
		background-position: top right;
		background-size: cover;
	}
	
	&-wrapper {
		position: relative;
		z-index: 3;
		.controller-image{
			position: absolute;
			right: 30px;
			top: -180px;
			width: 450px;
			@media (max-width: $laptop) {
				right: 250px;
				top: -300px;
			}
			@media (max-width: $tablet) {
				right: 80px;
				top: -390px;
			}
			@media (max-width: $mobile-l) {
				right: -150px;
				top: -320px;
			}
			@media (max-width: $mobile) {
				right: -90px;
				top: -190px;
				width: 184px;
			}
			
		}
		.title-wrapper {
			margin-bottom: 48px;
			z-index: 3;
			position: relative;
		}

		&-subtitle {
			color: #ffdbd7;
			margin-bottom: 64px;
			max-width: 580px;
		}
		&-games{
			display: flex;
			flex-wrap: wrap;
		}
		
	}
	.girl-image{
		position: absolute;
		left: 0;
		bottom: 0;
		width: 520px;
		@media (max-width: $laptop) {
			width: 420px;
			bottom: -230px;
			z-index: 4;
		}
		@media (max-width: $tablet) {
			display: none;
		}
		
	}
	.boy-image{
		position: absolute;
		right: 0;
		bottom: 0;
		width: 500px;
		z-index: 4;
		@media (max-width: 1790px) {
			z-index: 2;
		}
		@media (max-width: $laptop) {
			width: 360px;
			bottom: -140px;
			z-index: 4;
		}
		@media (max-width: $tablet) {
			display: none;
		}
		
	}
	
}