@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@400;600;700;800;900&display=swap");
@font-face {
  font-family: "SuperCorn";
  src: url("./asset/font/SuperCorn/SuperCorn.woff") format("woff"), url("./asset/font/SuperCorn/SuperCorn.woff2") format("woff2"), url("./asset/font/SuperCorn/SuperCorn.ttf") format("true-type");
  font-style: normal;
  font-weight: 400;
}
*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

body {
  font: 16px "Montserrat Alternates", sans-serif;
  background: #E6D9FF;
  color: #e6d9ff;
}

.container {
  max-width: 1264px;
}
@media (min-width: 320px) {
  .container {
    padding-right: 16px;
    padding-left: 16px;
  }
}
@media (min-width: 576px) {
  .container {
    padding-right: 32px;
    padding-left: 32px;
  }
}

h1 {
  margin: 0;
  font-family: "SuperCorn", sans-serif;
  font-size: 96px;
  line-height: 100%;
  color: #FFFFFF;
}
@media (max-width: 768px) {
  h1 {
    font-size: 80px;
  }
}
@media (max-width: 576px) {
  h1 {
    font-size: 64px;
  }
}

.content {
  margin: 0 auto;
  position: relative;
}

.overflow-block {
  overflow: hidden;
}

h2 {
  margin: 0;
  font-family: "SuperCorn", sans-serif;
  font-size: 80px;
  line-height: 100%;
  color: #FFFFFF;
  display: inline-flex;
  align-items: center;
}
@media (max-width: 576px) {
  h2 {
    font-size: 48px;
  }
}
h2::before {
  content: "";
  display: block;
  margin-right: 16px;
  width: 32px;
  min-width: 32px;
  height: 32px;
  background: #ed3b77;
  border-radius: 100%;
}
h2.secondary::before {
  background: #7429ff;
}

.title-wrapper {
  display: inline-block;
  padding: 12px 24px;
  background: #4300bf;
  border-radius: 24px;
}
.title-wrapper-secondary {
  background: #FFC229;
}
@media (max-width: 576px) {
  .title-wrapper {
    padding: 8px 16px;
  }
}

p {
  font-family: "Montserrat Alternates", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 133%;
  color: #101113;
  letter-spacing: -0.16px;
}
@media (max-width: 768px) {
  p {
    font-size: 16px;
    line-height: 125%;
  }
}

li {
  font-family: "Montserrat Alternates", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 133%;
  color: #101113;
  letter-spacing: -0.16px;
}
@media (max-width: 768px) {
  li {
    font-size: 16px;
    line-height: 125%;
  }
}

a {
  text-decoration: unset;
  color: #FFFFFF;
}
a:hover {
  text-decoration: unset;
}

.action-btn {
  position: relative;
  cursor: pointer;
  display: inline-flex;
  border-radius: 16px;
  padding: 24px 40px;
  background: #4300bf;
  color: #FFFFFF;
  font-size: 24px;
  font-weight: 800;
  letter-spacing: 0, 64px;
  text-transform: capitalize;
}
.action-btn::before {
  content: "";
  position: absolute;
  top: 8px;
  left: 8px;
  opacity: 0;
  max-width: 26px;
  width: 26px;
  height: 26px;
  background-image: url("./asset/image/paw-icon.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
}
@media (max-width: 576px) {
  .action-btn {
    padding: 16px 24px;
    font-size: 16px;
  }
  .action-btn::before {
    width: 12px;
  }
}
.action-btn:hover {
  background: #7429ff;
}
.action-btn:hover::before {
  opacity: 1;
}
.action-btn:active {
  background: #290075;
}
.action-btn:active::before {
  opacity: 1;
}
.action-btn-secondary {
  background: #ed3b77;
}
.action-btn-secondary:hover {
  background: #e26;
}
.action-btn-secondary:active {
  background: #d01654;
}

.content {
  margin: 0 auto;
  position: relative;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

section {
  border-radius: 120px;
  margin-bottom: 200px;
  padding: 200px 0;
}
@media (max-width: 1024px) {
  section {
    border-radius: 100px;
  }
}
@media (max-width: 768px) {
  section {
    margin-bottom: 160px;
    padding: 120px 0;
    border-radius: 80px;
  }
}
@media (max-width: 576px) {
  section {
    margin-bottom: 120px;
    padding: 136px 0;
    border-radius: 64px;
  }
}

img {
  max-width: 100%;
}

.info-page h1 {
  color: black;
}
.info-page h2 {
  color: black;
  margin-bottom: 8px;
}
.info-page h2::before {
  display: none;
}
.info-page h3 {
  color: black;
}
.info-page p {
  color: black;
}
.info-page ul {
  list-style: disc;
  padding-left: 2rem;
}
.info-page li {
  margin-bottom: 8px;
}

.gameCard {
  flex: 0 50%;
  margin-bottom: 40px;
}
.gameCard:nth-child(odd) {
  padding-right: 20px;
}
.gameCard:nth-child(even) {
  padding-left: 20px;
}
.gameCard-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #FFFFFF;
  border-radius: 40px;
  padding: 56px 40px;
}
.gameCard-image {
  max-width: 100%;
  margin-bottom: 24px;
}
.gameCard-text {
  margin-bottom: 12px;
}
.gameCard-link {
  position: relative;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  padding-bottom: 16px;
}
.gameCard-link p {
  margin: 0;
  font-weight: 800;
  text-transform: capitalize;
  color: #7429ff;
  font-size: 24px;
  line-height: 100%;
  margin-right: 20px;
}
.gameCard-link-arrow {
  padding-right: 12px;
}
.gameCard-link::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 2px;
  opacity: 0;
  background: #7429ff;
}
.gameCard-link:hover::before {
  opacity: 1;
  background: #7429ff;
}
.gameCard-link:active p {
  color: #4300bf;
}
.gameCard-link:active::before {
  background: #4300bf;
  opacity: 1;
}
.gameCard-link:active svg path {
  fill: #4300bf;
}
.gameCard-stores {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  gap: 32px;
}
@media (max-width: 1024px) {
  .gameCard .gameCard-wrapper {
    padding: 40px 32px;
  }
  .gameCard .gameCard-stores {
    justify-content: space-between;
  }
}
@media (max-width: 768px) {
  .gameCard {
    margin-bottom: 32px;
  }
  .gameCard:nth-child(odd) {
    padding-right: 16px;
  }
  .gameCard:nth-child(even) {
    padding-left: 16px;
  }
  .gameCard .gameCard-wrapper {
    padding: 32px 24px;
  }
  .gameCard .gameCard-text p {
    font-size: 16px;
  }
  .gameCard .gameCard-link {
    margin-bottom: 24px;
  }
  .gameCard .gameCard-link p {
    font-size: 20px;
  }
}
@media (max-width: 576px) {
  .gameCard {
    flex: 0 100%;
    margin-bottom: 24px;
  }
  .gameCard:nth-child(odd) {
    padding-right: unset;
  }
  .gameCard:nth-child(even) {
    padding-left: unset;
  }
  .gameCard .gameCard-link p {
    font-size: 16px;
  }
}

header {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  padding-top: 36px;
  z-index: 10;
}
header .header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  border-radius: 24px;
  padding: 0 24px;
}
header .header-wrapper-nav {
  display: flex;
  gap: 24px;
}
header .header-wrapper-nav li a {
  padding: 12px;
  text-transform: capitalize;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  color: #101113;
  cursor: pointer;
  border-radius: 12px;
  transition: all 0.3s;
}
header .header-wrapper-nav li a:hover {
  background: #ED3B77;
  color: #FFFFFF;
}
header .header-wrapper-nav li a:active {
  background: #D01654;
  color: #FFFFFF;
}
header .header-wrapper-burger {
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
}
header .header-wrapper-burger-button {
  padding: 12px 24px;
  background: #ED3B77;
  border-radius: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
@media (max-width: 460px) {
  header .header-wrapper-burger-button {
    padding: 8px;
  }
}
header .header-wrapper-burger-button p {
  font-weight: 800;
  font-size: 20px;
  line-height: 20px;
  color: #FFFFFF;
  margin: unset;
  letter-spacing: 0.8px;
}
@media (max-width: 460px) {
  header .header-wrapper-burger-button p {
    display: none !important;
  }
}
header .header-wrapper-burger-button .open-menu {
  display: block;
}
header .header-wrapper-burger-button .close-menu {
  display: none;
}
header .header-wrapper-burger-button.active {
  background: #4300BF;
}
header .header-wrapper-burger-button.active .open-menu {
  display: none;
}
header .header-wrapper-burger-button.active .close-menu {
  display: block;
}
header .header-wrapper-burger-nav {
  z-index: -1;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: url("./asset/image/mobile-menu-bg.png"), radial-gradient(62.27% 50% at 50% 50%, #FF6699 0%, #E63973 100%);
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: bottom left;
  transition: all 0.5s ease-in;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 576px) {
  header .header-wrapper-burger-nav {
    background: url("./asset/image/mobile-menu-bg-xs.png"), radial-gradient(62.27% 50% at 50% 50%, #FF6699 0%, #E63973 100%);
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: bottom left;
  }
}
header .header-wrapper-burger-nav ul {
  padding-top: 124px;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
header .header-wrapper-burger-nav ul li {
  padding: 12px;
}
header .header-wrapper-burger-nav ul a {
  font-size: 48px;
  line-height: 48px;
  font-weight: 700;
  text-transform: capitalize;
  padding: 12px;
  cursor: pointer;
}
@media (max-width: 576px) {
  header .header-wrapper-burger-nav ul a {
    font-size: 32px;
    line-height: 32px;
  }
}
header .header-wrapper-burger-nav .cat-image {
  margin-top: auto;
  margin-left: auto;
  width: 304px;
}
@media (max-width: 576px) {
  header .header-wrapper-burger-nav .cat-image {
    width: 152px;
  }
}
header .header-wrapper-burger-nav .cat-image img {
  max-width: 100%;
}
header .header-wrapper-burger-nav.closed {
  height: 0;
}
@keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
header .header-wrapper-burger-nav.opened ul {
  animation: fade-in-fwd 0.8s ease-in-out both;
  animation-delay: 0.5s;
}

footer {
  position: relative;
  border-radius: 120px 120px 0 0;
  overflow: hidden;
  background: #F7F2FF;
  padding: 17px 0;
}
@media (max-width: 576px) {
  footer {
    padding: 34px 60px;
  }
}
footer .footer-icon {
  position: absolute;
}
footer .footer-icon-left {
  left: 0;
  top: 0;
}
footer .footer-icon-right {
  right: 20px;
  bottom: 0;
}
footer .footer-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
@media (max-width: 576px) {
  footer .footer-wrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
}
footer .footer-wrapper-link {
  color: #7429FF;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  text-transform: capitalize;
  padding: 12px;
  cursor: pointer;
}
footer .footer-wrapper-text {
  color: #A574FF;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  padding: 12px;
  margin: unset;
}

.hero {
  position: relative;
  background: url("./asset/image/hero-bg.png"), radial-gradient(69.72% 50% at 50% 50%, #2998FF 0%, #521FBF 100%);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;
  padding: 242px 0 144px 0;
  border-top-left-radius: unset;
  border-top-right-radius: unset;
}
@media (max-width: 1440px) {
  .hero {
    padding: 186px 0 66px 0;
  }
}
@media (max-width: 1024px) {
  .hero {
    background: url("./asset/image/hero-bg-tablet.png"), radial-gradient(69.72% 50% at 50% 50%, #2998FF 0%, #521FBF 100%);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: cover;
  }
}
@media (max-width: 768px) {
  .hero {
    background: url("./asset/image/hero-bg-mobile-l.png"), radial-gradient(69.72% 50% at 50% 50%, #2998FF 0%, #521FBF 100%);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: cover;
  }
}
@media (max-width: 576px) {
  .hero {
    background: url("./asset/image/hero-bg-mobile.png"), radial-gradient(69.72% 50% at 50% 50%, #2998FF 0%, #521FBF 100%);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: cover;
  }
}
.hero .plane-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 470px;
}
.hero .plane-image img {
  max-width: 100%;
}
@media (max-width: 1440px) {
  .hero .plane-image {
    top: 120px;
    width: 310px;
  }
}
.hero .giraffe-image {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 495px;
}
@media (max-width: 1780px) {
  .hero .giraffe-image {
    bottom: -300px;
  }
}
@media (max-width: 1440px) {
  .hero .giraffe-image {
    bottom: -450px;
    left: -100px;
  }
}
@media (max-width: 1024px) {
  .hero .giraffe-image {
    display: none;
  }
}
.hero .panda-image {
  position: absolute;
  bottom: -90px;
  left: 300px;
  width: 346px;
  z-index: 3;
}
@media (max-width: 1440px) {
  .hero .panda-image {
    bottom: -290px;
    left: 230px;
  }
}
@media (max-width: 1024px) {
  .hero .panda-image {
    bottom: 0px;
    left: 230px;
  }
}
@media (max-width: 930px) {
  .hero .panda-image {
    display: none;
  }
}
.hero .cat-image {
  position: absolute;
  bottom: 0;
  left: 600px;
  width: 304px;
}
@media (max-width: 1780px) {
  .hero .cat-image {
    display: none;
  }
}
.hero .dog-image {
  position: absolute;
  bottom: 0;
  right: 700px;
  width: 304px;
}
@media (max-width: 1600px) {
  .hero .dog-image {
    bottom: -80px;
    right: 520px;
  }
}
@media (max-width: 1200px) {
  .hero .dog-image {
    display: none;
  }
}
.hero .car-image {
  position: absolute;
  right: 40px;
  bottom: -130px;
  width: 444px;
}
@media (max-width: 1440px) {
  .hero .car-image {
    right: -100px;
    bottom: -250px;
  }
}
@media (max-width: 1024px) {
  .hero .car-image {
    transform: scaleX(-1);
    left: -100px;
    right: unset;
  }
}
@media (max-width: 768px) {
  .hero .car-image {
    bottom: -40px;
    left: -130px;
  }
}
@media (max-width: 600px) {
  .hero .car-image {
    width: 260px;
    bottom: -160px;
    left: -70px;
  }
}
.hero-wrapper h1 {
  margin-bottom: 40px;
  color: #C6FF29;
}
.hero-wrapper-content {
  display: flex;
}
.hero-wrapper-content p {
  color: #e6d9ff;
  margin-bottom: 64px;
}
.hero-wrapper-content-image {
  min-width: 512px;
  max-width: 512px;
  margin-right: -130px;
}
@media (max-width: 1440px) {
  .hero-wrapper .hero-wrapper-content {
    gap: 20px;
  }
  .hero-wrapper .hero-wrapper-content-image {
    min-width: 455px;
    max-width: 455px;
    margin-right: unset;
  }
}
@media (max-width: 1024px) {
  .hero-wrapper .hero-wrapper-content {
    flex-direction: column;
  }
  .hero-wrapper .hero-wrapper-content-image {
    align-self: end;
    margin-top: -110px;
    min-width: 412px;
    max-width: 412px;
  }
}
@media (max-width: 768px) {
  .hero-wrapper .hero-wrapper-content-image {
    margin-top: -30px;
    min-width: 412px;
    max-width: 412px;
  }
}
@media (max-width: 576px) {
  .hero-wrapper h1 {
    margin-bottom: 32px;
  }
  .hero-wrapper p {
    margin-bottom: 48px;
  }
  .hero-wrapper .hero-wrapper-content {
    gap: 40px;
  }
  .hero-wrapper .hero-wrapper-content-image {
    align-self: center;
    margin-top: unset;
    min-width: 328px;
    max-width: 328px;
  }
}

.apps {
  position: relative;
  z-index: 2;
  background: url("./asset/image/apps-bg.png"), radial-gradient(100.28% 50% at 50% 50%, #EC8F3A 0%, #F35E0A 100%);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;
}
@media (max-width: 1024px) {
  .apps {
    background: url("./asset/image/apps-bg-tablet.png"), radial-gradient(100.28% 50% at 50% 50%, #EC8F3A 0%, #F35E0A 100%);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: cover;
  }
}
@media (max-width: 576px) {
  .apps {
    background: url("./asset/image/apps-bg-mobile.png"), radial-gradient(100.28% 50% at 50% 50%, #EC8F3A 0%, #F35E0A 100%);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: cover;
  }
}
.apps-wrapper {
  position: relative;
  z-index: 3;
}
.apps-wrapper .controller-image {
  position: absolute;
  right: 30px;
  top: -180px;
  width: 450px;
}
@media (max-width: 1440px) {
  .apps-wrapper .controller-image {
    right: 250px;
    top: -300px;
  }
}
@media (max-width: 1024px) {
  .apps-wrapper .controller-image {
    right: 80px;
    top: -390px;
  }
}
@media (max-width: 768px) {
  .apps-wrapper .controller-image {
    right: -150px;
    top: -320px;
  }
}
@media (max-width: 576px) {
  .apps-wrapper .controller-image {
    right: -90px;
    top: -190px;
    width: 184px;
  }
}
.apps-wrapper .title-wrapper {
  margin-bottom: 48px;
  z-index: 3;
  position: relative;
}
.apps-wrapper-subtitle {
  color: #ffdbd7;
  margin-bottom: 64px;
  max-width: 580px;
}
.apps-wrapper-games {
  display: flex;
  flex-wrap: wrap;
}
.apps .girl-image {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 520px;
}
@media (max-width: 1440px) {
  .apps .girl-image {
    width: 420px;
    bottom: -230px;
    z-index: 4;
  }
}
@media (max-width: 1024px) {
  .apps .girl-image {
    display: none;
  }
}
.apps .boy-image {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 500px;
  z-index: 4;
}
@media (max-width: 1790px) {
  .apps .boy-image {
    z-index: 2;
  }
}
@media (max-width: 1440px) {
  .apps .boy-image {
    width: 360px;
    bottom: -140px;
    z-index: 4;
  }
}
@media (max-width: 1024px) {
  .apps .boy-image {
    display: none;
  }
}

.info {
  background: url("./asset/image/info-bg.png"), radial-gradient(69.72% 50% at 50% 50%, rgb(116, 41, 255) 0%, rgb(87, 31, 191) 100%);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;
}
@media (max-width: 1024px) {
  .info {
    background: url("./asset/image/info-bg-tablet.png"), radial-gradient(69.72% 50% at 50% 50%, rgb(116, 41, 255) 0%, rgb(87, 31, 191) 100%);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: cover;
  }
}
.info-wrapper .title-wrapper {
  margin-bottom: 64px;
}
.info-wrapper .title-wrapper h2 {
  color: #290075;
}
.info-wrapper .title-wrapper.before-content {
  margin-bottom: 105px;
}
.info-wrapper .title-wrapper.before-content h2::before {
  background: #C6FF29;
}
.info-wrapper-subtitle {
  margin-bottom: 64px;
}
.info-wrapper-subtitle p {
  font-family: "SuperCorn", sans-serif;
  font-size: 88px;
  line-height: 100%;
  color: #C6FF29;
}
.info-wrapper-subtitle p span {
  color: #e6d9ff;
}
.info-wrapper-content {
  display: flex;
  flex-wrap: wrap;
}
.info-wrapper-content.only-text {
  margin-bottom: 120px;
}
.info-wrapper-content-item {
  flex: 0 50%;
}
.info-wrapper-content-item:nth-child(odd) {
  padding-right: 20px;
}
.info-wrapper-content-item:nth-child(even) {
  padding-left: 20px;
}
.info-wrapper-content-item-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #FFFFFF;
  border-radius: 32px;
  padding: 32px 40px;
  font-weight: 600;
  font-size: 24px;
  line-height: 133%;
}
.info-wrapper-content-item-wrapper .img {
  max-width: 412px;
  align-self: center;
  margin-bottom: 54px;
  margin-top: -125px;
}
.info-wrapper-content-item-wrapper p {
  margin: 0;
}
.info-wrapper-content-item-wrapper h3 {
  font-family: "SuperCorn", sans-serif;
  font-size: 56px;
  line-height: 100%;
  color: #290075;
  margin-bottom: 24px;
}
@media (max-width: 1024px) {
  .info-wrapper .info-wrapper-subtitle p {
    font-size: 72px;
  }
  .info-wrapper .info-wrapper-content.only-text {
    margin-bottom: 105px;
  }
  .info-wrapper .info-wrapper-content-item-wrapper .img {
    max-width: 308px;
    margin-top: -95px;
  }
}
@media (max-width: 768px) {
  .info-wrapper .title-wrapper.before-content {
    margin-bottom: 80px;
  }
  .info-wrapper .info-wrapper-subtitle p {
    font-size: 52px;
  }
  .info-wrapper .info-wrapper-content.only-text {
    margin-bottom: 80px;
  }
  .info-wrapper .info-wrapper-content-item:nth-child(odd) {
    padding-right: 16px;
  }
  .info-wrapper .info-wrapper-content-item:nth-child(even) {
    padding-left: 16px;
  }
  .info-wrapper .info-wrapper-content-item-wrapper {
    padding: 24px 32px;
  }
  .info-wrapper .info-wrapper-content-item-wrapper h3 {
    font-size: 40px;
  }
  .info-wrapper .info-wrapper-content-item-wrapper .img {
    max-width: 232px;
    margin-top: -55px;
  }
}
@media (max-width: 576px) {
  .info-wrapper .title-wrapper.before-content {
    margin-bottom: 64px;
  }
  .info-wrapper .info-wrapper-subtitle p {
    font-size: 32px;
  }
  .info-wrapper .info-wrapper-content {
    gap: 40px;
  }
  .info-wrapper .info-wrapper-content.only-text {
    gap: 32px;
    margin-bottom: 72px;
  }
  .info-wrapper .info-wrapper-content-item {
    flex: 0 100%;
  }
  .info-wrapper .info-wrapper-content-item:nth-child(odd) {
    padding-right: unset;
  }
  .info-wrapper .info-wrapper-content-item:nth-child(even) {
    padding-left: unset;
  }
  .info-wrapper .info-wrapper-content-item-wrapper .img {
    margin-top: -50px;
  }
}

.contact {
  background: url("./asset/image/apps-bg.png"), radial-gradient(100.28% 50% at 50% 50%, #EC8F3A 0%, #F35E0A 100%);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;
  padding: 120px 0;
}
@media (max-width: 576px) {
  .contact {
    padding: 64px 0;
  }
}
.contact-wrapper {
  position: relative;
}
.contact-wrapper .email-image {
  position: absolute;
  right: 0;
  top: -370px;
  width: 574px;
}
@media (max-width: 768px) {
  .contact-wrapper .email-image {
    width: 444px;
    right: -80px;
    top: -270px;
  }
}
@media (max-width: 576px) {
  .contact-wrapper .email-image {
    width: 252px;
    right: 30px;
    top: -240px;
  }
}
.contact-wrapper .bird-image {
  position: absolute;
  left: -180px;
  bottom: -290px;
  width: 410px;
}
@media (max-width: 1440px) {
  .contact-wrapper .bird-image {
    left: -110px;
  }
}
@media (max-width: 1024px) {
  .contact-wrapper .bird-image {
    left: -20px;
  }
}
@media (max-width: 768px) {
  .contact-wrapper .bird-image {
    width: 366px;
  }
}
@media (max-width: 576px) {
  .contact-wrapper .bird-image {
    width: 235px;
    transform: scaleX(-1);
    left: unset;
    right: -60px;
    bottom: -150px;
  }
}
.contact .title-wrapper {
  position: relative;
  z-index: 3;
  margin-bottom: 48px;
}
.contact .title-wrapper h2::before {
  background: #C6FF29;
}
.contact-form input, .contact-form textarea {
  border: unset;
  border-radius: 32px;
  background: #FFFFFF;
  width: 100%;
  position: relative;
  padding: 1.5rem 2rem 1.5rem 1rem;
  color: #101113;
  font-family: "Montserrat Alternates", sans-serif;
  line-height: 140%;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.54px;
}
.contact-form input::placeholder, .contact-form textarea::placeholder {
  color: #1f1f1f;
  opacity: 0.5;
}
.contact-form input:focus-visible, .contact-form textarea:focus-visible {
  outline: unset;
}
@media (max-width: 576px) {
  .contact-form input, .contact-form textarea {
    font-size: 16px;
  }
}
.contact-form textarea {
  resize: none;
  height: 100%;
}
@media (max-width: 576px) {
  .contact-form textarea {
    height: 200px;
  }
}
.contact-form .input-box {
  position: relative;
}
.contact-form .input-box-error {
  display: none;
  position: absolute;
  bottom: -20px;
  left: 0;
  margin: unset;
  color: #4300bf;
  font-family: "IBM Plex Mono", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 2.4px;
}
@media (max-width: 1024px) {
  .contact-form .input-box-error {
    line-height: 16px;
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .contact-form .input-box-error {
    line-height: 12px;
    font-size: 16px;
  }
}
.contact-form .input-box.message {
  height: 100%;
}
.contact-form .input-box.invalid input, .contact-form .input-box.invalid textarea {
  border-color: #FF1A1A;
}
.contact-form .input-box.invalid .input-box-error {
  display: block;
}
.contact-form-inputs:nth-child(1) {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
@media (max-width: 768px) {
  .contact-form-inputs:nth-child(1) {
    margin-bottom: 32px;
    gap: 32px;
  }
}
@media (max-width: 576px) {
  .contact-form-inputs:nth-child(1) {
    margin-bottom: 24px;
    gap: 24px;
  }
}
.contact-form .contact-form-button {
  margin-top: 40px;
  display: flex;
}
@media (max-width: 768px) {
  .contact-form .contact-form-button {
    justify-content: flex-end;
  }
}
@media (max-width: 576px) {
  .contact-form .contact-form-button {
    justify-content: flex-start;
  }
}

.modal-window {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 16;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 0 16px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.modal-window.hidden {
  display: none;
}
.modal-window-wrapper {
  position: sticky;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #FFFFFF;
  padding: 32px 64px;
  width: 100%;
  max-width: 640px;
  min-height: 280px;
  text-align: center;
}
@media (min-width: 768px) {
  .modal-window-wrapper {
    min-height: 360px;
  }
}
.modal-window-wrapper h4 {
  margin: unset;
  font-size: 24px;
  color: #4300bf;
  font-weight: 700;
}
.modal-window-wrapper p {
  font-size: 14px;
  font-weight: 700;
}
@media (min-width: 576px) {
  .modal-window-wrapper p {
    font-size: 24px;
  }
}
.modal-window-wrapper-close {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 12px;
  background-color: #FFFFFF;
}
@media (min-width: 768px) {
  .modal-window-wrapper-close {
    width: 64px;
    height: 64px;
  }
}