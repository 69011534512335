.modal-window {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	z-index: 16;
	background-color: rgba(0, 0, 0, .4);
	padding: 0 16px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	&.hidden {
		display: none;
	}

	&-wrapper {
		position: sticky;
		top: 50%;
		transform: translateY(-50%);
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		background-color: $white;
		padding: 32px 64px;
		width: 100%;
		max-width: 640px;

		min-height: 280px;
		text-align: center;

		//@include mq(medium) {
		//}
		@media (min-width: 768px) {
			min-height: 360px;

		}

		h4 {
			margin: unset;
			font-size: 24px;
			color: $primary;
			font-weight: 700;
		}

		p {
			font-size: 14px;
			font-weight: 700;
			//@include mq(mobile) {
			//	@include get-font(18);
			//}
			@media (min-width: 576px) {
				font-size: 24px;
			}
		}

		&-close {
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 40px;
			height: 40px;
			position: absolute;
			top: 0;
			right: 0;
			padding: 12px;
			background-color: $accent;
			//@include mq(medium) {
			//	width: 64px;
			//	height: 64px;
			//}
			@media (min-width: 768px) {
				width: 64px;
				height: 64px;
			}
		}
	}
}
