.contact{
	background: url("./asset/image/apps-bg.png"), $secondary-gradient;
	//background: $primary-gradient;
	background-repeat: no-repeat;
	background-position: top right;
	background-size: cover;
	padding: 120px 0;

	@media (max-width: 576px) {
		padding: 64px 0;
	}
	
	&-wrapper{
		position: relative;
		
		.email-image{
			position: absolute;
			right: 0;
			top: -370px;
			width: 574px;
			
			@media (max-width: $mobile-l) {
				width: 444px;
				right: -80px;
				top: -270px;
			}
			@media (max-width: $mobile) {
				width: 252px;
				right: 30px;
				top: -240px;
			}
		}
		.bird-image{
			position: absolute;
			left: -180px;
			bottom: -290px;
			width: 410px;
			@media (max-width: $laptop) {
				left: -110px;
			}
			@media (max-width: $tablet) {
				left: -20px;
			}
			@media (max-width: $mobile-l) {
				width: 366px;
			}
			@media (max-width: $mobile) {
				width: 235px;
				transform: scaleX(-1);
				left: unset;
				right: -60px;
				bottom: -150px;
			}
			
		}
	}
	.title-wrapper{
		position: relative;
		z-index: 3;
		margin-bottom: 48px;
		h2{
			&::before{
				background: #C6FF29;
			}
		}
	}

	&-form{
		input, textarea {
			border: unset;
			border-radius: 32px;
			background: $white;
			width: 100%;
			position: relative;
			padding: 1.5rem 2rem 1.5rem 1rem;
			color: $textColor;
			font-family: "Montserrat Alternates", sans-serif;
			line-height: 140%;
			font-size: 24px;
			font-weight: 600;
			letter-spacing: .54px;

			&::placeholder {
				color: #1f1f1f;
				opacity: .5;
			}

			&:focus-visible {
				outline: unset;
			}
			@media (max-width: 576px) {
				font-size: 16px;
			}
		}
		
		textarea{
			resize: none;

			height: 100%;
			@media (max-width: 576px) {
				height: 200px;
			}
		}
		
		.input-box {
			position: relative;

			&-error{
				display: none;
				position: absolute;
				bottom: -20px;
				left: 0;
				margin: unset;
				//color: #FF1A1A;
				color: $primary;
				font-family: 'IBM Plex Mono', sans-serif;
				font-size: 24px;
				font-weight: 600;
				line-height: 12px;
				letter-spacing: 2.4px;
				@media (max-width: $tablet) {
					line-height: 16px;
					font-size: 16px;
				}
				@media (max-width: $mobile-l) {
					line-height: 12px;
					font-size: 16px;
				}
				
			}
			&.message {
				height: 100%;
			}
			&.invalid{
				input,textarea{
					border-color: #FF1A1A;
				}

				.input-box-error {
					display: block;
				}
			}
		}
		
		&-inputs {
			&:nth-child(1) {
				display: flex;
				flex-direction: column;
				gap: 40px;
				@media (max-width: 768px) {
					margin-bottom: 32px;
					gap: 32px;
				}
				@media (max-width: 576px) {
					margin-bottom: 24px;
					gap: 24px;
				}

			}
		}

		.contact-form-button{
			margin-top: 40px;
			display: flex;

			@media (max-width: 768px) {
				justify-content: flex-end;
			}

			@media (max-width: 576px) {
				justify-content: flex-start;
			}

		}
	}
}