*,
*::after,
*::before {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html,
body {
	height: 100%;
	margin: 0;
	padding: 0;
	position: relative;
}

body {
	font: 16px 'Montserrat Alternates', sans-serif;
	background: #E6D9FF;
	color: #e6d9ff
}
.container{
	max-width: 1264px;
	@media (min-width: 320px) {
		padding-right: 16px;
		padding-left: 16px;
	}
	@media (min-width: 576px) {
		padding-right: 32px;
		padding-left: 32px;
	}
	
}
h1 {
	margin: 0;
	font-family: 'SuperCorn', sans-serif;
	font-size: 96px;
	line-height: 100%;
	color: $white;
	
	@media (max-width: $mobile-l) {
		font-size: 80px;
	}
	
	@media (max-width: $mobile) {
		font-size: 64px;
	}
}
.content{
	margin: 0 auto;
	position: relative;
}
.overflow-block{
	overflow: hidden;
}
h2 {
	margin: 0;
	font-family: 'SuperCorn', sans-serif;
	font-size: 80px;
	line-height: 100%;
	color: $white;
	display: inline-flex;
	align-items: center;
	@media (max-width: $mobile) {
		font-size: 48px;
	}
	
	&::before{
		content:'';
		display: block;
		margin-right: 16px;
		width: 32px;
		min-width: 32px;
		height: 32px;
		background: $secondary;
		border-radius: 100%;
	}
	&.secondary{
		&::before{
			background: #7429ff;
		}
	}
}
.title-wrapper{
	display: inline-block;
	padding: 12px 24px;
	background: $primary;
	border-radius: 24px;
	
	&-secondary{
		background: $yellow;
	}
	@media (max-width: $mobile) {
		padding: 8px 16px;
	}
}

p {
	font-family: 'Montserrat Alternates', sans-serif;
	font-size: 24px;
	font-weight: 600;
	line-height: 133%;
	color: $textColor;
	letter-spacing: -0.16px;
	
	@media (max-width: $mobile-l) {
		font-size: 16px;
		line-height: 125%;
	}
}
li {
	font-family: 'Montserrat Alternates', sans-serif;
	font-size: 24px;
	font-weight: 600;
	line-height: 133%;
	color: $textColor;
	letter-spacing: -0.16px;
	
	@media (max-width: $mobile-l) {
		font-size: 16px;
		line-height: 125%;
	}
}

a {
	text-decoration: unset;
	color: $white;
	&:hover {
		text-decoration: unset;
	}
}

.action-btn{
	position: relative;
	cursor: pointer;
	display: inline-flex;
	border-radius: 16px;
	padding: 24px 40px;
	background: $primary;
	color: $white;
	font-size: 24px;
	font-weight: 800;
	letter-spacing: -0,64px;
	text-transform: capitalize;
	
	
	
	&::before{
		content: '';
		position: absolute;
		top: 8px;
		left: 8px;
		opacity: 0;
		max-width: 26px;
		width: 26px;
		height: 26px;
		background-image: url("./asset/image/paw-icon.svg");
		background-repeat: no-repeat;
		background-size: 100% auto;
		
	}
	@media (max-width: $mobile) {
		padding: 16px 24px;
		font-size: 16px;
		&::before{
			width: 12px;
		}
	}
	&:hover{
		background: #7429ff;
		&::before{
			opacity: 1;
		}
	}
	
	&:active{
		background: #290075;
		&::before{
			opacity: 1;
		}
	}
	
	&-secondary{
		background: $secondary;
		&:hover{
			background: #e26;
		}
		&:active{
			background: #d01654;
		}
	}

}

.content {
	margin: 0 auto;
	position: relative;
}
ul{
	margin: 0;
	padding: 0;
	list-style: none;
}
section{
	border-radius: 120px;
	margin-bottom: 200px;
	padding: 200px 0;
	
	@media (max-width: $tablet) {
		border-radius: 100px;
	}
	
	@media (max-width: $mobile-l) {
		margin-bottom: 160px;
		padding: 120px 0;
		border-radius: 80px;
	}
	
	@media (max-width: $mobile) {
		margin-bottom: 120px;
		padding: 136px 0;
		border-radius: 64px;
	}
}

img{
	max-width: 100%;
}

.info-page{
	h1{
		color: black;
	}
	h2{
		color: black;
		margin-bottom: 8px;
		&::before{
			display: none;
		}
	}
	h3{
		color: black;
	}
	p{
		color: black;
	}
	ul{
		list-style: disc;
		padding-left: 2rem;
	}
	li{
		margin-bottom: 8px;
	}
}