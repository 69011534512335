header{
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	padding-top: 36px;
	z-index: 10;

	.header-wrapper{
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: #FFFFFF;
		border-radius: 24px;
		padding: 0 24px;

		&-nav{
			display: flex;
			gap: 24px;
			
			li{
				a{
					padding: 12px;
					text-transform: capitalize;
					font-size: 18px;
					line-height: 28px;
					font-weight: 700;
					color: #101113;
					cursor: pointer;
					border-radius: 12px;
					transition: all .3s ;
					&:hover{
						background: #ED3B77;
						color: #FFFFFF;
					}
					&:active{
						background: #D01654;
						color: #FFFFFF;
					}
					
				}
			}
		}
		
		&-burger{
			justify-content: flex-end;
			align-items: flex-end;
			flex-direction: column;
			&-button{
				padding: 12px 24px;
				background: #ED3B77;
				border-radius: 16px;
				display: inline-flex;
				justify-content: center;
				align-items: center;
				gap: 8px;
				
				@media (max-width: 460px) {
					padding: 8px;
				}
				
				p{
					font-weight: 800;
					font-size: 20px;
					line-height: 20px;
					color: #FFFFFF;
					margin: unset;
					letter-spacing: .8px;
					@media (max-width: 460px) {
						display: none !important;
					}
				}
				.open-menu {
					display: block;
				}

				.close-menu {
					display: none;
				}

				&.active {
					background: #4300BF;
					.open-menu {
						display: none;
					}

					.close-menu {
						display: block;
					}
				}

			}
			&-nav{
				
				z-index: -1;
				overflow: hidden;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				background: url("./asset/image/mobile-menu-bg.png"),radial-gradient(62.27% 50% at 50% 50%, #FF6699 0%, #E63973 100%);
				background-repeat: no-repeat;
				background-size: auto 100%;
				background-position: bottom left;
				transition: all .5s ease-in;
				height: calc(100vh);
				display: flex;
				flex-direction: column;
				justify-content: center;
				@media (max-width: $mobile) {
					background: url("./asset/image/mobile-menu-bg-xs.png"),radial-gradient(62.27% 50% at 50% 50%, #FF6699 0%, #E63973 100%);
					background-repeat: no-repeat;
					background-size: auto 100%;
					background-position: bottom left;
				}
				ul{
					padding-top: 124px;
					margin-top: auto;
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 24px;
					li{
						padding: 12px;
					}
					a{
						font-size: 48px;
						line-height: 48px;
						font-weight: 700;
						text-transform: capitalize;
						padding: 12px;
						cursor: pointer;
						
						@media (max-width: 576px) {
							font-size: 32px;
							line-height: 32px;
						}
					}
				}
				.cat-image{
					margin-top: auto;
					margin-left: auto;
					width: 304px;
					@media (max-width: 576px) {
						width: 152px;

					}
					img{
						max-width: 100%;
					}
				}
				&.closed {
					height: 0;
					//padding-top: 0;
				}
				&.opened {
					@keyframes fade-in-fwd {
						0% {
							-webkit-transform: translateZ(-80px);
							transform: translateZ(-80px);
							opacity: 0;
						}
						100% {
							-webkit-transform: translateZ(0);
							transform: translateZ(0);
							opacity: 1;
						}
					}

					ul {
						animation: fade-in-fwd 0.8s ease-in-out both;
						animation-delay: .5s;

					}
				}
			}
		}
	}
}