$white: #FFFFFF;
$primary: #4300bf;
$secondary: #ed3b77;
$yellow: #FFC229;
$textColor: #101113;
$primary-gradient: radial-gradient(69.72% 50% at 50% 50%, rgb(116, 41, 255) 0%, rgb(87, 31, 191) 100%);
//$secondary-gradient: radial-gradient(62.27% 50% at 50% 50%, rgb(255, 102, 153) 0%, rgb(229, 57, 115) 100%);
$secondary-gradient: radial-gradient(100.28% 50% at 50% 50%, #EC8F3A 0%, #F35E0A 100%); 
$accent: #FFFFFF;
//breakpoints

$laptop: 1440px;
$tablet: 1024px;
$mobile-l: 768px;
$mobile: 576px;