.hero{
	position: relative;
	background: url("./asset/image/hero-bg.png"), radial-gradient(69.72% 50% at 50% 50%, #2998FF 0%, #521FBF 100%);
	background-repeat: no-repeat;
	background-position: top right;
	background-size: cover;
	padding: 242px 0 144px 0;
	border-top-left-radius: unset;
	border-top-right-radius: unset;
	
	@media (max-width: $laptop) {
		padding: 186px 0 66px 0;
	}
	@media (max-width: $tablet) {
		background: url("./asset/image/hero-bg-tablet.png"), radial-gradient(69.72% 50% at 50% 50%, #2998FF 0%, #521FBF 100%);
		background-repeat: no-repeat;
		background-position: top right;
		background-size: cover;
	}
	@media (max-width: $mobile-l) {
		background: url("./asset/image/hero-bg-mobile-l.png"), radial-gradient(69.72% 50% at 50% 50%, #2998FF 0%, #521FBF 100%);
		background-repeat: no-repeat;
		background-position: top right;
		background-size: cover;
	}
	@media (max-width: $mobile) {
		background: url("./asset/image/hero-bg-mobile.png"), radial-gradient(69.72% 50% at 50% 50%, #2998FF 0%, #521FBF 100%);
		background-repeat: no-repeat;
		background-position: top right;
		background-size: cover;
	}
	.plane-image{
		position: absolute;
		top: 0;
		right: 0;
		width: 470px;
		img{
			max-width: 100%;
		}
		@media (max-width: $laptop) {
			top: 120px;
			width: 310px;
		}
	}
	.giraffe-image{
		position: absolute;
		bottom: 0;
		left: 0;
		width: 495px;
		@media (max-width: 1780px) {
			bottom: -300px;
		}
		@media (max-width: $laptop) {
			bottom: -450px;
			left: -100px;
		}
		@media (max-width: $tablet) {
			display: none;
		}
	}
	.panda-image{
		position: absolute;
		bottom: -90px;
		left: 300px;
		width: 346px;
		z-index: 3;
		@media (max-width: $laptop) {
			bottom: -290px;
			left: 230px;
		}
		@media (max-width: $tablet) {
			bottom: 0px;
			left: 230px;
		}
		@media (max-width: 930px) {
			display: none;
		}
	}
	.cat-image{
		position: absolute;
		bottom: 0;
		left: 600px;
		width: 304px;
		@media (max-width: 1780px) {
			display: none;
		}
	}
	.dog-image{
		position: absolute;
		bottom: 0;
		right: 700px;
		width: 304px;
		@media (max-width: 1600px) {
			bottom: -80px;
			right: 520px;
		}
		@media (max-width: 1200px) {
			display: none;
		}
	}
	.car-image{
		position: absolute;
		right: 40px;
		bottom: -130px;
		width: 444px;
		@media (max-width: $laptop) {
			right: -100px;
			bottom: -250px;
		}
		@media (max-width: $tablet) {
			transform: scaleX(-1);
			left: -100px;
			right: unset;
		}
		@media (max-width: $mobile-l) {
			bottom: -40px;
			left: -130px;
		}
		@media (max-width: 600px) {
			width: 260px;
			bottom: -160px;
			left: -70px;
		}
	}
	&-wrapper{
		
		h1{
			margin-bottom: 40px;
			color: #C6FF29;
		}
		&-content{
			display: flex;
			p{
				color: #e6d9ff;
				margin-bottom: 64px;
			}
			&-image{
				min-width: 512px;
				max-width: 512px;
				margin-right: -130px;
			}
		}
		@media (max-width: $laptop) {
			.hero-wrapper-content{
				gap: 20px;
			}
			.hero-wrapper-content-image{
				min-width: 455px;
				max-width: 455px;
				margin-right: unset;
			}
		}
		@media (max-width: $tablet) {
			
			.hero-wrapper-content{
				flex-direction: column;
			}
			.hero-wrapper-content-image{
				align-self: end;
				margin-top: -110px;
				min-width: 412px;
				max-width: 412px;
			}
		}
		@media (max-width: $mobile-l) {
			
			.hero-wrapper-content-image{
				margin-top: -30px;
				min-width: 412px;
				max-width: 412px;
			}
		}
		
		@media (max-width: $mobile) {
			h1{
				margin-bottom: 32px;
			}
			p{
				margin-bottom: 48px;
			}
			.hero-wrapper-content{
				gap: 40px;
			}
			.hero-wrapper-content-image{
				align-self: center;
				margin-top: unset;
				min-width: 328px;
				max-width: 328px;
			}
			
		}
	}
}
