footer{
	position: relative;
	border-radius: 120px 120px 0 0;
	overflow: hidden;
	background: #F7F2FF;
	padding: 17px 0;
	
	@media(max-width: 576px){
		padding: 34px 60px;
	}

	.footer-icon{
		position: absolute;
		
		&-left{
			left: 0;
			top: 0;
		}

		&-right{
			right: 20px;
			bottom: 0;
		}
	}
	
	.footer-wrapper{
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 24px;

		@media(max-width: 576px){
			flex-direction: column;
			align-items: flex-start;
			gap: 12px;

		}

		&-link{
			color: #7429FF;
			font-weight: 700;
			font-size: 16px;
			line-height: 28px;
			text-transform: capitalize;
			padding: 12px;
			cursor: pointer;
		}

		&-text{
			color: #A574FF;
			font-weight: 700;
			font-size: 16px;
			line-height: 28px;
			padding: 12px;
			margin: unset;
		}
	}
}