.info{
	background: url("./asset/image/info-bg.png"), $primary-gradient;
	background-repeat: no-repeat;
	background-position: top right;
	background-size: cover;
	
	@media (max-width: $tablet) {
		background: url("./asset/image/info-bg-tablet.png"), $primary-gradient;
		background-repeat: no-repeat;
		background-position: top right;
		background-size: cover;
	}

	&-wrapper{
		.title-wrapper{
			margin-bottom: 64px;
			h2{
				color: #290075;
			}
			&.before-content{
				margin-bottom: 105px;
				
				h2{
					&::before{
						background: #C6FF29;
					}
				}
			}
		}
		&-subtitle{
			margin-bottom: 64px;
			p{
				font-family: 'SuperCorn', sans-serif;
				font-size: 88px;
				line-height: 100%;
				color:  #C6FF29 ;
				span{
					color: #e6d9ff;
				}
			}
		}
		&-content{
			display: flex;
			flex-wrap: wrap;
			&.only-text{
				margin-bottom: 120px;
			}
			&-item{
				flex: 0 50%;
				&:nth-child(odd){
					padding-right: 20px;

				}
				&:nth-child(even){
					padding-left: 20px;

				}
				
				&-wrapper{
					display: flex;
					flex-direction: column;
					height: 100%;
					background: $white;
					border-radius: 32px;
					padding: 32px 40px;
					font-weight: 600;
					font-size: 24px;
					line-height: 133%;
					
					.img{
						max-width: 412px;
						align-self: center;
						margin-bottom: 54px;
						margin-top: -125px;
					}
					p{
						margin: 0;
					}
					h3{
						font-family: 'SuperCorn', sans-serif;
						font-size: 56px;
						line-height: 100%;
						color: #290075;
						margin-bottom: 24px;
					}
				}
			}
		}
		@media (max-width: $tablet) {
			.info-wrapper-subtitle{
				p{
					font-size: 72px;
				}
			}
			.info-wrapper-content{
				&.only-text{
					margin-bottom: 105px;
				}
			}
			.info-wrapper-content-item-wrapper{
				.img{
					max-width: 308px;
					margin-top: -95px;
				}
			}
		}
		@media (max-width: $mobile-l) {
			.title-wrapper{
				&.before-content{
					margin-bottom: 80px;
				}
			}
			.info-wrapper-subtitle{
				p{
					font-size: 52px;
				}
			}
			.info-wrapper-content{
				&.only-text{
					margin-bottom: 80px;
				}
			}
			.info-wrapper-content-item{
				&:nth-child(odd){
					padding-right: 16px;
				}
				&:nth-child(even){
					padding-left: 16px;
				}
			}
			.info-wrapper-content-item-wrapper{
				padding: 24px 32px;
				h3{
					font-size: 40px;
				}
				.img{
					max-width: 232px;
					margin-top: -55px;
				}
			}
		}
		@media (max-width: $mobile) {
			.title-wrapper{
				&.before-content{
					margin-bottom: 64px;
				}
			}
			.info-wrapper-subtitle{
				p{
					font-size: 32px;
				}
			}
			.info-wrapper-content{
				gap: 40px;
				&.only-text{
					gap: 32px;
					margin-bottom: 72px;
				}
			}
			.info-wrapper-content-item{
				flex: 0 100%;

				&:nth-child(odd){
					padding-right: unset;
				}
				&:nth-child(even){
					padding-left: unset;
				}
			}
			.info-wrapper-content-item-wrapper{
				.img{
					margin-top: -50px;
				}
			}
		}
	}
}